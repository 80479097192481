<template>
  <div>
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      v-b-tooltip.hover.v-success
      variant="success"
      :title="empresa === 'APMIX' ? 'Asignar Técnico' : 'Despachar Luminarias'"
      class="btn-icon"
      @click="abrirModalAsignacion"
    >
      <feather-icon icon="UserCheckIcon" />
    </b-button>
    <b-modal
      v-model="showDialogoAsignacion"
      :title="tituloModal"
      modal-class="modal-primary"
      size="xl"
      hide-footer
      ok-title="Aceptar"
      no-close-on-backdrop
      @hide="cerrarModalAsignacion"
    >
      <b-overlay
        opacity="0.70"
        variant="transparent"
        blur="1rem"
        :show="loadingAsignacion"
      >
        <validation-observer ref="simpleRules">
          <form>
            <b-row>
              <b-col cols="12">
                <input
                  ref="inputQR"
                  v-model="textoEscaneado"
                  style="position: absolute; top: -9999px"
                  class="textoQr qr-textarea"
                  type="text"
                  :disabled="mostrarCampos"
                  @keydown="extraerCodigos"
                >
              </b-col>
              <b-col
                cols="12"
              >
                <h2
                  v-if="!mostrarCampos"
                  class="text-uppercase"
                  style="display: flex; justify-content: center; font-weight: bold; color: #006394"
                >
                  Escanea un código para realizar el despacho 🚛
                </h2>
                <h2
                  v-else
                  class="text-uppercase"
                  style="display: flex; justify-content: center; font-weight: bold; color: #006394"
                >
                  rellena los siguientes datos para realizar el despacho 📄✍🏻
                </h2>
                <hr>
              </b-col>
              <b-col
                v-if="empresa === 'APMIX' && mostrarCampos"
                cols="12"
              >
                <h5 class="text-primary">
                  Seleccione un Técnico
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Asignar Técnico"
                    rules="required"
                  >
                    <v-select
                      v-model="ticketAsignar.tecnicoEncargado"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="tecnicos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="empresa !== 'APMIX' && mostrarCampos"
                cols="6"
              >
                <h5 class="text-primary">
                  Persona que recibe
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de Persona que recibe"
                    rules="required"
                  >
                    <b-form-input
                      v-model="datosDespacho.personaRecibe"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="empresa !== 'APMIX' && mostrarCampos"
                cols="6"
              >
                <h5 class="text-primary">
                  DPI
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="de DPI"
                    rules="required"
                  >
                    <cleave
                      id="customDelimiter"
                      v-model="datosDespacho.dpi"
                      class="form-control qr-textarea"
                      :raw="false"
                      :options="customDelimiter"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="mostrarCampos"
                cols="12"
              >
                <h5 class="text-primary">
                  Comentarios
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="comentarios"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-good-table
                  :columns="filteredColumns"
                  :rows="rows"
                  style-class="vgt-table"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'opciones'">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        v-b-tooltip.hover.v-danger
                        variant="danger"
                        title="Remover Luminaria"
                        class="btn-icon"
                        @click="removerLuminaria(props.rowIndex)"
                      >
                        <feather-icon
                          icon="XIcon"
                          size="18"
                        />
                      </b-button>
                    </span>
                    <span v-else-if="props.column.field === 'estado'">
                      <b-badge variant="light-warning">
                        {{ estadoSeguimiento(props.row.estado) }}
                      </b-badge>
                    </span>
                    <span v-else-if="props.column.field === 'grupo' && mostrarCampos === true && empresa !== 'APMIX'">
                      <div
                        style="width: 100px; height: 75px"
                      >
                        <b-form-group>
                          <validation-provider
                            :key="'grupo-' + props.index"
                            #default="{ errors }"
                            :name="'grupo de la serie ' + props.row.serie"
                            rules="required"
                          >
                            <div style="position: absolute; width: 200px;">
                              <v-select
                                :key="'grupo-' + props.index"
                                v-model="props.row.grupoAsignado"
                                label="nombre"
                                :state="errors.length > 0 ? false:null"
                                :options="grupos"
                                @input="updateGrupo(props.row, props.index, $event)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </span>
                    <span
                      v-else-if="props.column.field === 'mejora' && mostrarCampos === true && empresa !== 'APMIX'"
                      style="width: 220px"
                    >
                      <b-form-group>
                        <validation-provider
                          :key="'mejora-' + props.index"
                          #default="{ errors }"
                          :name="'destino de la serie ' + props.row.serie"
                          rules="required"
                        >
                          <div style="position: absolute; width: 290px;">
                            <v-select
                              :key="'mejora-' + props.index"
                              v-model="props.row.mejoraSeleccionada"
                              label="nombre"
                              :state="errors.length > 0 ? false:null"
                              :options="opcionesReporte"
                              @input="updateMejora(props.row, props.index, $event)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span v-else-if="props.column.field === 'fechaCreacion'">
                      <span>{{ calcularFecha(props.row.fechaCreacion) }}</span>
                    </span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
            <br>
            <hr>
            <b-row>
              <b-col
                style="margin-top: -20px"
                class="d-flex justify-content-end demo-inline-spacing"
                cols="12"
              >
                <b-button
                  v-if="!mostrarCampos && empresa !== 'APMIX'"
                  variant="success"
                  @click="busquedaMobile"
                >
                  Buscar Manualmente con Cámara
                  <Icon
                    :style="{fontSize: '18'}"
                    icon="streamline:mobile-phone-camera"
                  />
                </b-button>
                <b-button
                  v-if="!mostrarCampos"
                  variant="warning"
                  @click="busquedaManual"
                >
                  Buscar Manualmente
                  <feather-icon
                    size="18"
                    icon="SearchIcon"
                  />
                </b-button>
                <b-button
                  v-if="mostrarCampos"
                  variant="primary"
                  @click="regresarIngresar"
                >
                  Escanear más luminarias
                  <feather-icon
                    size="18"
                    icon="PlusIcon"
                  />
                </b-button>
                <b-button
                  v-if="!mostrarCampos"
                  variant="primary"
                  @click="pasarAsignar"
                >
                  Siguiente
                  <feather-icon
                    size="18"
                    icon="ArrowRightIcon"
                  />
                </b-button>
                <b-button
                  v-if="mostrarCampos"
                  variant="primary"
                  @click="validarAsignacion"
                >
                  Despachar
                  <feather-icon
                    size="18"
                    icon="TruckIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-overlay>
    </b-modal>
    <div>
      <b-modal
        v-model="mostrarBusqueda"
        title="Búsqueda manual"
        size="md"
        no-close-on-backdrop
        modal-class="modal-primary"
        hide-footer
      >
        <componente-busqueda-manual
          :filtro-busqueda="'DESPACHO'"
          :tipo="'DESPACHO'"
          :origen="despachoOrigen"
          @item-recibido="agregarItem"
        />
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="mostrarModalMobile"
        title="Búsqueda Mobile"
        size="md"
        no-close-on-backdrop
        modal-class="modal-primary"
        hide-footer
        @hide="empezarIntervalo()"
      >
        <b-overlay
          opacity="0.70"
          variant="transparent"
          blur="1rem"
          :show="loadingAdicionMobile"
        >
          <b-row>
            <b-col cols="12">
              <escaner-mobile
                :origen="'DESPACHOS'"
                @datos="escaneoMobileLuminaria"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <center>
                  <h5 class="text-primary">
                    SERIE DE LUMINARIA:
                  </h5>
                  <b-form-input
                    :value="textoEscaneado && textoEscaneado.serie"
                    class="text-center"
                    disabled
                  />
                </center>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCol,
  BFormGroup, BFormInput,
  BFormTextarea,
  BModal,
  BOverlay,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import {
  mensajeError, mensajeInformativo, notificacionError, notificacionInformativa,
} from '@/utils/mensajes'
import { findLuminariasLab, getUtilsLaboratorio, updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'
import { findNemas, updateCreateNema } from '@/utils/laboratorio/nemas'
import { calcularFecha, formatDate } from '@/utils/fechas'
import { generarReporteDespachos } from '@/utils/bodega/reporteDespachosGeneral'
import ComponenteBusquedaManual from '@/components/laboratorio/componenteBusquedaManual.vue'
import Cleave from 'vue-cleave-component'
import { Base64 } from 'js-base64'
import { updateCreateDespachos } from '@/utils/despachos/despachos'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/vue2'
import EscanerMobile from '@/components/escanersQr/escanerMobile.vue'

export default {
  components: {
    EscanerMobile,
    Icon,
    ComponenteBusquedaManual,
    BRow,
    BCol,
    Cleave,
    BBadge,
    BModal,
    BButton,
    vSelect,
    BOverlay,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    tecnicos: {
      type: Array,
      required: true,
      default: () => [],
    },
    origen: {
      type: String,
      required: true,
    },
    columnasNemas: {
      type: Array,
      default: null,
    },
    columnasLuminarias: {
      type: Array,
      default: null,
    },
    origenReporte: {
      type: Object,
      required: true,
    },
    grupos: {
      type: Array,
      default: () => [],
    },
    opcionesReporte: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      customDelimiter: {
        delimiters: [' ', ' '],
        blocks: [4, 5, 4],
        uppercase: true,
        numericOnly: true,
      },
      decodificar: '',
      grupoAsignado: '',
      despachoOrigen: '',
      mejoraSeleccionada: '',
      datosDespacho: {
        personaRecibe: '',
        dpi: '',
      },
      busqueda: '',
      datosEscaneadosMobile: null,
      mostrarBusqueda: false,
      mostrarModalMobile: false,
      loadingAsignacion: false,
      mostrarCampos: false,
      textoEscaneado: null,
      serieExtraida: null,
      tipoExtraido: null,
      comentarios: null,
      timeoutId: null,
      empresa: '',
      tituloModal: 'Asignar',
      tecnicoAsignado: null,
      showDialogoAsignacion: false,
      loadingAdicionMobile: false,
      ticketAsignar: { tecnicoEncargado: null },
      usuario: JSON.parse(localStorage.getItem('userData')),
      rows: [],
      datosEncontrados: [],
      selecciones: {},
    }
  },
  computed: {
    filteredColumns() {
      const columns = this.columnasNemas || this.columnasLuminarias

      return columns.filter(column => {
        if ((column.field === 'grupo' || column.field === 'mejora') && (!this.mostrarCampos || this.empresa === 'APMIX')) {
          return false
        }
        if (this.mostrarCampos && column.field === 'fechaCreacion') {
          return false
        }
        if (this.mostrarCampos && column.field === 'tipo') {
          return false
        }
        return true
      })
    },
  },
  watch: {
    'textoEscaneado.serie': function (newSerie) {
      if (newSerie) {
        this.procesarSerie(newSerie)
      }
    },
  },
  created() {
    if (this.origen === 'luminarias') {
      this.despachoOrigen = 'LUMINARIAS_MODULO'
    } else if (this.origen === 'nemas') {
      this.despachoOrigen = 'NEMAS_MODULO'
    }
    if (this.usuario.company.name === 'APMIX') {
      this.empresa = 'APMIX'
    } else {
      this.empresa = 'APVN'
    }
    this.estados = getUtilsLaboratorio(3)
    this.titulosOrigen()
  },
  methods: {
    formatDate,
    escaneoMobileLuminaria(datosEscaneados) {
      this.textoEscaneado = datosEscaneados
    },
    empezarIntervalo() {
      this.timeoutId = setInterval(() => {
        this.$nextTick(() => {
          this.$refs.inputQR.focus()
        })
      }, 500)
    },
    procesarSerie(serie) {
      try {
        this.loadingAdicionMobile = true
        this.loadingAsignacion = true

        if (!serie) {
          notificacionError('No se encontró la serie en el texto escaneado')
          this.loadingAsignacion = false
          this.loadingAdicionMobile = false
          return
        }

        this.serieExtraida = serie

        const luminariaExiste = this.rows.find(luminaria => luminaria.serie === this.serieExtraida)
        if (luminariaExiste) {
          notificacionError('Luminaria ya agregada 😑', 'Escanea una nueva! 😁')
          this.limpiarCampos()
          this.loadingAdicionMobile = false
          this.loadingAsignacion = false
          return
        }

        const filtro = {
          where: {
            serie: this.serieExtraida,
          },
        }

        this.buscarLuminaria(filtro).then(luminariaEncontrada => {
          if (luminariaEncontrada && luminariaEncontrada.length > 0) {
            const primeraLuminaria = luminariaEncontrada[0]
            if (primeraLuminaria.estado !== 'DESPACHO' && this.origen === 'luminarias') {
              mensajeError('La luminaria a asignar debe de estar en estado "Lista para Despacho"')
              this.limpiarCampos()
              return
            }
            this.rows.push(primeraLuminaria)
            notificacionInformativa('Luminaria agregada', 'Luminaria escaneada y agregada correctamente.')
            this.limpiarCampos()
          } else {
            notificacionError('Luminaria no encontrada')
            this.limpiarCampos()
          }
        }).finally(() => {
          this.loadingAdicionMobile = false
          this.loadingAsignacion = false
          this.mostrarModalMobile = false
        })
      } catch (error) {
        console.error(error)
        this.loadingAdicionMobile = false
        this.loadingAsignacion = false
      }
    },
    agregarItem(item) {
      try {
        const itemExistente = this.rows.find(row => row.id === item.id)

        if (itemExistente) {
          mensajeError('Este item ya fue agregado a la lista para despachar')
        } else {
          this.rows.push(item)
          this.cerrarModalBusqueda()
        }
      } catch (error) {
        console.error(error)
        notificacionError('Error', 'Algo ha salido mal.')
      }
    },
    busquedaMobile() {
      clearInterval(this.timeoutId)
      try {
        this.mostrarModalMobile = true
      } catch (error) {
        console.error(error)
        clearInterval(this.timeoutId)
      }
    },
    busquedaManual() {
      this.mostrarBusqueda = true
      clearInterval(this.timeoutId)
    },
    calcularFecha,
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    validarAsignacion() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarAsignacion()
          }
        })
    },
    updateGrupo(row, rowIndex, value) {
      this.$set(this.selecciones, row.id, {
        ...this.selecciones[row.id],
        grupoAsignado: value,
      })
    },
    updateMejora(row, rowIndex, value) {
      this.$set(this.selecciones, row.id, {
        ...this.selecciones[row.id],
        mejoraSeleccionada: value,
      })
    },
    async guardarAsignacion() {
      try {
        if (this.rows.length === 0 && this.origen === 'luminarias') {
          mensajeError('Debes escanear al menos una luminaria')
          this.mostrarCampos = false
          this.timeoutId = setInterval(() => {
            this.$nextTick(() => {
              this.$refs.inputQR.focus()
            })
          }, 500)
        } if (this.rows.length === 0 && this.origen === 'nemas') {
          mensajeError('Debes escanear al menos una nema')
          this.mostrarCampos = false
          this.timeoutId = setInterval(() => {
            this.$nextTick(() => {
              this.$refs.inputQR.focus()
            })
          }, 500)
        } else {
          this.loadingAsignacion = true

          const datosUsuario = usuario => ({
            id: usuario.id,
            nombre: `${usuario.firstName} ${usuario.firstSurname}`,
            email: `${usuario.email}`,
            telefono: '',
          })

          const datosTecnico = tecnico => ({
            id: tecnico?.id || '',
            nombre: `${tecnico?.firstName || ''} ${tecnico?.firstSurname || ''}`,
            email: `${tecnico?.email || ''}`,
            telefono: '',
          })

          // eslint-disable-next-line no-restricted-syntax,consistent-return
          const promises = this.rows.map(async luminaria => {
            const seleccion = this.selecciones[luminaria.id] || {}
            // eslint-disable-next-line no-param-reassign
            luminaria.estado = 'DESPACHADA'
            luminaria.seguimiento.push({
              estado: 'DESPACHO',
              fecha: new Date(),
              usuarioAsignado: datosTecnico(this.ticketAsignar.tecnicoEncargado),
              mejorasRealizadas: seleccion.mejoraSeleccionada,
              grupo: seleccion.grupoAsignado,
              comentarios: this.comentarios,
              usuario: datosUsuario(this.usuario),
            })
            luminaria.tracking.push({
              estado: 'DESPACHADA',
              fecha: new Date(),
              usuario: datosUsuario(this.usuario),
            })
            if (this.origen === 'luminarias') {
              // eslint-disable-next-line no-return-await
              return await updateCreateLuminariaLab(luminaria, 1)
            }
            if (this.origen === 'nemas') {
              // eslint-disable-next-line no-return-await
              return await updateCreateNema(luminaria, 1)
            }
          })
          await Promise.all(promises)
          await this.crearDespachos(this.rows)
          await this.generarDocumentoDespacho(this.rows)
          this.$emit('actualizar')
          this.showDialogoAsignacion = false
          mensajeInformativo('Despacho Realizado', 'Despacho realizado realizado correctamente!')
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingAsignacion = false
      }
    },
    async crearDespachos(itemsDespacho) {
      try {
        const tipoEntrada = {
          luminarias: 'LUMINARIAS',
          nemas: 'NEMAS',
        }
        const itemsDespachados = itemsDespacho.map(item => {
          const seleccion = this.selecciones[item.id] || {}
          return {
            id: item.id || '',
            tipo: item.tipo || '',
            serie: item.serie || '',
            tipoLampara: item.tipoLampara?.nombre || '',
            potencia: item.potencia?.nombre || '',
            grupo: seleccion.grupoAsignado?.nombre,
            mejoraRealizada: seleccion.mejoraSeleccionada?.nombre,
          }
        })
        const despacho = {
          companyId: this.usuario.company.id,
          usuarioEncargado: {
            nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            email: this.usuario.email,
          },
          fechaCreacion: new Date(),
          nombreReceptor: this.datosDespacho?.personaRecibe,
          datosDespacho: {
            tecnico: this.tecnicoAsignado?.nombre,
            dpi: this.datosDespacho?.dpi,
            comentario: this.comentarios,
          },
          tipo: tipoEntrada[this.origen],
          estado: 'DESPACHADO',
          origen: 'MANUAL',
          seguimiento: [],
          tracking: [
            {
              estado: 'DESPACHADO',
              fechaHora: new Date(),
            },
          ],
          items: itemsDespachados,
        }
        await updateCreateDespachos(despacho, 2)
      } catch (error) {
        console.error(error)
      } finally {
        notificacionInformativa('Despacho Creado', 'Despacho realizado y agregado a módulo despachos!')
      }
    },
    async generarDocumentoDespacho(despachadas) {
      try {
        const fileResumen = await generarReporteDespachos(despachadas, this.origenReporte, this.usuario, this.grupoAsignado, this.mejoraSeleccionada, this.datosDespacho.personaRecibe, this.datosDespacho.dpi)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = `Despacho de ${this.origenReporte.nombre} ${formatDate(new Date(), '/')}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado', `Reporte de despacho de ${this.origenReporte.nombre}`)
      } catch (error) {
        console.error(error)
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar')
      } finally {
        this.loadingDespacho = false
      }
    },
    extraerCodigos(event) {
      try {
        if (event.keyCode === 13) {
          event.preventDefault()
          this.loadingAsignacion = true
          if (this.empresa === 'APMIX') {
            if (this.origen === 'luminarias') {
              const removerSegundoCodigo = /S-N[Ññ]\s*([^\s]+)/

              const matchSegundoCodigo = this.textoEscaneado.match(removerSegundoCodigo)

              if (matchSegundoCodigo) {
              // eslint-disable-next-line prefer-destructuring
                this.serieExtraida = matchSegundoCodigo[1]

                const luminariaExistente = this.rows.find(luminaria => luminaria.serie === this.serieExtraida)

                if (luminariaExistente && this.origen === 'luminarias') {
                  mensajeError('Luminaria ya agregada')
                  this.limpiarCampos()
                  this.loadingAsignacion = false
                  return
                }

                const filtro = {
                  where: {
                    serie: matchSegundoCodigo[1],
                  },
                }

                this.buscarLuminaria(filtro)
                  .then(luminariaEncontrada => {
                    if (luminariaEncontrada && luminariaEncontrada.length > 0) {
                      const primeraLuminaria = luminariaEncontrada[0]

                      if (primeraLuminaria.estado !== 'DESPACHO' && this.origen === 'luminarias') {
                        mensajeError('La luminaria a asignar debe de estar en estado "Lista para Despacho"')
                        this.limpiarCampos()
                        return
                      }
                      if (primeraLuminaria.estado !== 'DESPACHO' && this.origen === 'nemas') {
                        mensajeError('La nema a asignar debe de estar en estado "Lista para Despacho"')
                        this.limpiarCampos()
                        return
                      }

                      this.rows.push(primeraLuminaria)
                      this.limpiarCampos()
                    } else {
                      if (this.origen === 'luminarias') {
                        mensajeError('Luminaria no encontrada')
                        this.limpiarCampos()
                      }
                      if (this.origen === 'nemas') {
                        mensajeError('Nema no encontrada')
                        this.limpiarCampos()
                      }
                    }
                  })
              } else {
                mensajeError('Texto del escáner no válido')
                this.limpiarCampos()
              }
            }
            if (this.origen === 'nemas') {
              const removerSegundoCodigo = /[Ss]-?[Nn][Ññ]?\s*([^\s]+)/i
              const matchSegundoCodigo = this.textoEscaneado.match(removerSegundoCodigo)

              // eslint-disable-next-line prefer-destructuring
              this.serieExtraida = matchSegundoCodigo[1]
              if (matchSegundoCodigo) {
                const luminariaExistente = this.rows.find(luminaria => luminaria.serie === this.serieExtraida)
                if (luminariaExistente) {
                  mensajeError('Nema ya agregada')
                  this.limpiarCampos()
                  this.loadingAsignacion = false
                  return
                }
                const filtro = {
                  where: {
                    serie: matchSegundoCodigo[1],
                  },
                }
                this.buscarLuminaria(filtro)
                  .then(luminariaEncontrada => {
                    if (luminariaEncontrada && luminariaEncontrada.length > 0) {
                      const primeraLuminaria = luminariaEncontrada[0]
                      if (primeraLuminaria.estado !== 'DESPACHO') {
                        mensajeError('La nema a asignar debe de estar en estado "Lista para Despacho"')
                        this.limpiarCampos()
                        return
                      }

                      this.rows.push(primeraLuminaria)
                      this.limpiarCampos()
                    } else {
                      mensajeError('Nema no encontrada')
                      this.limpiarCampos()
                    }
                  })
              } else {
                mensajeError('Texto del escáner no válido')
                this.limpiarCampos()
              }
            }
          } else if (this.empresa !== 'APMIX') {
            if (this.origen === 'luminarias') {
              const decodificado = JSON.parse(Base64.decode(this.textoEscaneado))
              this.serieExtraida = decodificado.serie

              const luminariaExiste = this.rows.find(luminaria => luminaria.serie === this.serieExtraida)

              if (luminariaExiste) {
                mensajeError('Luminaria ya agregada')
                this.limpiarCampos()
                this.loadingAsignacion = false
                return
              }

              const filtro = {
                where: {
                  serie: this.serieExtraida,
                },
              }
              this.buscarLuminaria(filtro).then(luminariaEcontrada => {
                if (luminariaEcontrada && luminariaEcontrada.length > 0) {
                  const primeraLuminaria = luminariaEcontrada[0]
                  this.rows.push(primeraLuminaria)
                  this.limpiarCampos()
                } else {
                  mensajeError('Luminaria no encontrada!')
                  this.limpiarCampos()
                }
              })
            }
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingAsignacion = false
      }
    },
    limpiarCampos() {
      this.textoEscaneado = null
      this.tipoExtraido = null
      this.serieExtraida = null
    },
    // eslint-disable-next-line consistent-return
    async buscarLuminaria(filtro = null) {
      try {
        if (this.origen === 'luminarias') {
          this.loadingAsignacion = true
          const filter = filtro || {
            where: {
              companyId: this.usuario.company.id,
              serie: this.serieExtraida,
            },
          }
          return await findLuminariasLab(filter)
        }
        if (this.origen === 'nemas') {
          this.loadingAsignacion = true
          const filter = filtro || {
            where: {
              companyId: this.usuario.company.id,
              serie: this.serieExtraida,
            },
          }
          return await findNemas(filter)
        }
      } catch (error) {
        console.error('Error al buscar', error)
        this.loadingAsignacion = false
        return null
      }
    },
    removerLuminaria(index) {
      this.rows.splice(index, 1)
    },
    abrirModalAsignacion() {
      this.showDialogoAsignacion = true
      try {
        this.timeoutId = setInterval(() => {
          this.$nextTick(() => {
            this.$refs.inputQR.focus()
          })
        }, 500)
      } catch (error) {
        console.error(error)
        clearInterval(this.timeoutId)
      }
    },
    cerrarModalBusqueda() {
      try {
        this.mostrarBusqueda = false
        this.busqueda = ''
        this.datosEncontrados = []
        this.timeoutId = setInterval(() => {
          this.$nextTick(() => {
            this.$refs.inputQR.focus()
          })
        }, 500)
      } catch (error) {
        console.error(error)
      }
    },
    cerrarModalAsignacion() {
      try {
        this.rows = []
        this.mostrarCampos = false
        this.ticketAsignar = { tecnicoEncargado: null }
        this.showDialogoAsignacion = false
        this.comentarios = null
        clearInterval(this.timeoutId)
        this.$refs.inputQR = null
      } catch (error) {
        console.error(error)
      } finally {
        clearInterval(this.timeoutId)
      }
    },
    regresarIngresar() {
      this.mostrarCampos = false
      this.timeoutId = setInterval(() => {
        this.$nextTick(() => {
          this.$refs.inputQR.focus()
        })
      }, 500)
    },
    pasarAsignar() {
      if (this.rows.length === 0 && this.origen === 'nemas') {
        mensajeError('Debes escanear al menos una nema')
      } else if (this.rows.length === 0 && this.origen === 'luminarias') {
        mensajeError('Debes escanear al menos una luminaria')
      } else {
        this.mostrarCampos = true
        clearInterval(this.timeoutId)
      }
    },
    titulosOrigen() {
      if (this.origen === 'nemas') {
        this.tituloModal = 'Asignar Nemas'
      } else if (this.origen === 'luminarias') {
        if (this.empresa === 'APMIX') {
          this.tituloModal = 'Asignar Luminarias'
        } else {
          this.tituloModal = 'Despachos de Luminarias'
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select.open-up .vs__dropdown-menu {
  top: auto;
  bottom: 100%;
}
</style>
