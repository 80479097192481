<template>
  <div>
    <b-overlay
      opacity="0.80"
      variant="transparent"
      blur="0.40rem"
      :show="show"
    >
      <template
        #overlay
      >
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-row>
        <b-col cols="12">
          <div>
            <center>
              <h3
                style="font-weight: bold"
                class="text-uppercase"
              >
                ¿Estas seguro que deseas remover
                <span class="text-danger">
                  {{ tituloOrigen }}
                </span>?
              </h3>
            </center>
          </div>
        </b-col>
      </b-row>
      <hr style="margin-top: -3px">
      <div style="margin-top: -5px">
        <center>
          <span class="text-muted text-uppercase">Previsualización de Item a Remover</span>
        </center>
      </div>
      <br>
      <div v-if="origen === 'MANTENIMIENTOS'">
        <b-row>
          <b-col cols="4">
            <h6 class="text-primary text-uppercase">
              <span>
                <center>
                  Serie de Lámpara
                </center>
              </span>
            </h6>
            <b-form-group>
              <b-form-input
                id="disabledInput"
                :value="item.codigo"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <h6 class="text-primary text-uppercase">
              <span>
                <center>
                  Clase de Lámpara
                </center>
              </span>
            </h6>
            <b-form-group>
              <b-form-input
                id="disabledInput"
                :value="item && item.modeloLampara && item.modeloLampara.nombre"
                disabled
                class="text-center"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <h6 class="text-primary text-uppercase">
              <span>
                <center>
                  Tipo Lámpara
                </center>
              </span>
            </h6>
            <b-form-group>
              <b-form-input
                id="disabledInput"
                :value="item && item.modelo && item.modelo.nombre"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="origen === 'LUMINARIAS'">
        <b-row>
          <b-col :cols="item.tipo ? 4 : 6">
            <h6 class="text-primary text-uppercase">
              <span>
                <center>
                  Serie de Lámpara
                </center>
              </span>
            </h6>
            <b-form-group>
              <b-form-input
                id="disabledInput"
                :value="item.serie"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col :cols="item.tipo ? 4 : 6">
            <h6 class="text-primary text-uppercase">
              <span>
                <center>
                  Tipo de Lámpara
                </center>
              </span>
            </h6>
            <b-form-group>
              <b-form-input
                id="disabledInput"
                :value="item.tipoLampara.nombre"
                disabled
                class="text-center"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="item.tipo"
            cols="4"
          >
            <h6 class="text-primary text-uppercase">
              <span>
                <center>
                  Tipo
                </center>
              </span>
            </h6>
            <b-form-group>
              <b-form-input
                id="disabledInput"
                :value="item && item.tipo"
                class="text-center"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr>
      <b-row>
        <b-col cols="12">
          <center>
            <b-col cols="4">
              <b-button
                variant="danger"
                size="lg"
                block
                @click="confirmar"
              >
                <span
                  class="text-uppercase"
                  style="font-weight: bold"
                >
                  Remover
                </span>
                <Icon
                  :style="{fontSize: '24'}"
                  icon="material-symbols:delete-outline"
                />
              </b-button>
            </b-col>
          </center>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BFormInput, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import {
  mensajeConfirmar, mensajeError, mensajeInformativo, notificacionInformativa,
} from '@/utils/mensajes'
import { updateCreateLuminaria } from '@/utils/mantenimiento/bodega/bodegaUtils'
import { updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => null,
    },
    origen: {
      type: String,
      required: true,
    },
    tituloOrigen: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    async confirmar() {
      try {
        const confirm = await mensajeConfirmar('¿Estas seguro? 🤨', '¿Estas seguro que deseas remover este item?', 'warning')
        if (!confirm) return
        this.show = true
        if (this.origen === 'MANTENIMIENTOS') {
          const body = this.item
          if (!this.item.tracking) {
            this.item.tracking = []
          }
          body.oldCompanyId = body.companyId
          body.companyId = `${body.oldCompanyId}-R`
          body.tracking.push({
            estado: 'REMOVIDO',
            fechaHora: new Date(),
            usuario: {
              nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              id: this.usuario.id,
            },
          })
          await updateCreateLuminaria(this.item, 1)
        } else if (this.origen === 'LUMINARIAS') {
          const body = this.item
          if (!this.item.tracking) {
            this.item.tracking = []
          }
          body.oldCompanyId = body.companyId
          body.companyId = `${body.oldCompanyId}-R`
          body.tracking.push({
            estado: 'REMOVIDO',
            fechaHora: new Date(),
            usuario: {
              nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              id: this.usuario.id,
            },
          })
          await updateCreateLuminariaLab(this.item, 1)
        }
        notificacionInformativa('Item Removido', 'Item Removido Correctamente!😁')
        mensajeInformativo('Item Removido', 'Item Removido Correctamente! 😁')
        this.$emit('cerrar-modal')
        this.$emit('actualizar')
      } catch (error) {
        console.error(error)
        mensajeError('Algo ha salido mal', 'Intentalo nuevamente')
      } finally {
        this.show = false
      }
    },
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>
